/*===================== 
	Premery bg color 
=====================*/
/* Hex */
.footer-top .widget_tag_cloud a:hover,
.bg-primary,
.meet-ask-row:after,
.dlab-separator.style-skew[class*='style-']:after,
.dlab-separator.style-skew[class*='style-']:before,
.dlab-tilte-inner.skew-title:after,
.date-style-2 .post-date,
.date-style-3.skew-date .post-date,
.date-style-3.skew-date .post-date:before,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after,
.widget-title:after,
.site-button,
.comments-area .comment-form p input[type='submit'],
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.footer-line:after,
.testimonial-1 .quote-left:before,
.testimonial-2 .quote-left:before,
.site-filters .active > [class*='site-button'],
.list-num-count > li:before,
.date-style-4 .post-date strong,
.date-style-3 .post-date,
.date-style-2 .post-date,
#myNavbar li.active a,
.skew-angle:after,
.header-style-4.style-1 .header-nav .nav > li.active a:before,
.header-style-4.style-1 .header-nav .nav > li:hover > a:before,
.dlab-project-left:after,
.service-carousel .owl-prev,
.service-carousel .owl-next,
.about-us .dlab-tabs .nav-tabs li a.active,
.testimonial-text.bg-primary,
.sidenav ul li a:hover,
.owl-theme.dots-style-2 .owl-dots .owl-dot.active span,
.owl-btn-1.primary .owl-prev,
.owl-btn-1.primary .owl-next,
.owl-btn-2.primary .owl-prev,
.owl-btn-2.primary .owl-next,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active span,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover span,
.product-item-size .btn.active,
.ui-widget-header,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span,
.owl-item.active.center .testimonial-text,
.icon-bx-wraper.hover:hover,
.portfolio-sep,
.portfolio-ic .dlab-img-overlay1:before,
.dlab-box-bg.active .icon-box-btn .site-button,
.dlab-accordion.primary .acod-head a,
.side-bar .widget_tag_cloud a:hover,
.time-line:after,
.line-right:after,
.line-left:after,
.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active,
.box-item-service:hover .item-service-content,
.box-item-service:hover h5,
.box-item-service:hover .icon-bx-md,
.tp-caption.rev-btn.tc-btnshadow.tp-rs-menulink,
.la-anim-10,
.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.pagination li.active a,
.rev-slider .tparrows,
.fc-unthemed .fc-day-header.fc-widget-header,
.fc-unthemed .fc-button.fc-state-default:hover,
.fc-unthemed .fc-button.fc-state-down,
.fc-unthemed .fc-button.fc-state-active,
.fc-unthemed .fc-button.fc-state-default,
.fc-unthemed .fc-button.fc-state-default,
.featured-bx.style2 .featured-type.featured-top,
.search-filter.filter-style1 .bootstrap-select div.dropdown-menu ul li a:hover,
.search-filter.filter-style1 .bootstrap-select div.dropdown-menu ul li.selected,
.work-box.style1 .box-count,
.work-box.style1:hover,
.bootstrap-select.btn-group .dropdown-menu li a:hover,
.tag-editor .tag-editor-tag,
.tag-editor .tag-editor-delete,
.imageuploadify .imageuploadify-images-list button.btn-default,
.listing-timing-box .listing-timing-header,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar,
.slider-wrapper .previousButton,
.slider-wrapper .nextButton {
    background-color: #e4329400;
}

/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover,
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover,
.owl-btn-2.primary .owl-next:hover {
    background-color: rgb(228, 50, 147);
}
.about-us .dlab-tabs .nav-tabs li a.active {
    border-right: 4px solid rgb(228, 50, 147);
}

/* Rgba color */
.dlab-info-has.bg-primary,
.dlab-info-has.bg-secondry:hover,
.tp-bannertimer.tp-bottom,
.header-transparent .is-fixed .main-bar {
    background-color: rgba(255, 94, 165, 0.9);
}
.main-slider .tp-shapewrapper.ov-tp {
    background-color: rgba(255, 94, 165, 0.75);
}
/* Box Shadow */
.side-bar .widget_tag_cloud a:hover {
    box-shadow: 5px 5px 10px 0 rgba(255, 94, 165, 0.15);
}
.team-section .team-content,
.tp-overlay-video {
    background-color: rgba(255, 94, 165, 0.7);
}
/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.primary li:before,
.breadcrumb-row ul li a,
.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a .header-nav .nav > li .sub-menu li a:hover,
.header-nav .nav > li:hover > a,
.header-nav .nav > li .mega-menu > li ul a:hover,
.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .sub-menu li:hover > a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover,
blockquote:before,
ol.comment-list li.comment .reply a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
button.scroltop,
.testimonial-1 .testimonial-position,
.testimonial-4 .testimonial-name:after,
.testimonial-2 .testimonial-position,
.testimonial-3 .testimonial-position,
.testimonial-3 .quote-left:before,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.dlab-tabs .nav-tabs > li > a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.site-button.outline,
.site-button-link:hover,
.item-info-in ul li a:hover,
.dlab-post-meta.text-primary i,
.dlab-post-meta.text-primary ul li,
.dlab-post-meta.text-primary ul li a,
.dlab-post-meta i,
.comments-area p:before,
.about-8-service .icon-bx-wraper:hover a,
.about-service .icon-bx-wraper:hover a,
.dlab-box-bg .icon-box-btn .site-button,
.list-2 li:hover:before,
.site-filters.style1 li.active a,
.site-filters.style1.white li.active a,
.site-button-link.black:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover,
.work-box.style1 .dlab-tilte,
.work-box.style1:hover .box-count,
.blog-post.style2 .dlab-post-meta ul li i,
.icon-box-list .icon-cell.bg-gray,
.icon-box-info .icon-cell.bg-gray,
.content-header .title i,
.post-rating .average-rating,
.listing-time-list li.open,
.average-reviews-info .average-reviews,
.average-reviews-single .average-reviews-info .average-reviews-in,
.average-reviews-single .average-reviews-info span,
.sale-box .sale-date .title a,
.lg-outer .lg-toogle-thumb,
.lg-outer .lg-toogle-thumb:hover,
.package-meta li .fa {
    color: rgba(255, 133, 33, 1);
}
@media only screen and (max-width: 991px) {
    .nav-dark.header-nav .nav > li > a:hover,
    .nav-dark.header-nav .nav > li > a:active,
    .nav-dark.header-nav .nav > li > a:focus {
        color: rgba(255, 133, 33, 1);
    }
}
/*===================== 
	Premery border color 
=====================*/
.footer-top .widget_tag_cloud a:hover,
.navbar-toggle,
.testimonial-4 .testimonial-pic,
.testimonial-4:after,
.testimonial-4 [class*='quote-'],
button.scroltop,
blockquote,
.widget_gallery li:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
input[type='checkbox']:checked + label:before,
input[type='checkbox'] + label:hover:before,
input[type='radio'] + label:hover:before,
.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover,
.side-bar .widget_tag_cloud a:hover,
.work-box.style1,
.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: rgba(255, 133, 33, 1);
}
.owl-item.active.center .testimonial-8 .testimonial-text:after {
    border-top: 15px solid rgba(255, 133, 33, 1);
}
.dlab-tabs.border-top .nav-tabs > li > a.active,
.header-nav .nav > li .sub-menu,
.header-nav .nav > li .mega-menu,
.center .testimonial-2.testimonial-bg .testimonial-text:after {
    border-top-color: rgba(255, 133, 33, 1);
}
.port-style1 .port-box {
    border-left: 3px solid rgba(255, 133, 33, 1);
}
.choseus-tabs .nav li a.active {
    border-bottom: 2px solid rgba(255, 133, 33, 1);
}

/*===================== 
	New Important
 =====================*/
a.text-primary:hover {
    color: rgba(255, 133, 33, 1) !important;
}
.text-primary,
.site-footer .footer-bottom a:hover,
footer .widget_services ul li a:hover {
    color: rgba(255, 133, 33, 1) !important;
}
.bg-primary {
    background-color: rgba(255, 133, 33, 1) !important;
}
a.bg-primary:focus,
a.bg-primary:hover {
    background-color: rgba(255, 133, 33, 1) !important;
}

/* background rgba */
.icon-box-list .icon-cell.bg-gray,
.icon-box-info .icon-cell.bg-gray {
    background-color: rgb(255, 255, 255);
}
.dlab-img-overlay11:after,
.dlab-img-overlay11:before,
.dlab-img-overlay12:after,
.dlab-img-overlay12:before,
.dlab-img-overlay1:after,
.dlab-img-overlay1:before,
.dlab-img-overlay2:after,
.dlab-img-overlay2:before,
.dlab-img-overlay3:after,
.dlab-img-overlay3:before,
.dlab-img-overlay4:after,
.dlab-img-overlay4:before,
.dlab-img-overlay5:after,
.dlab-img-overlay5:before,
.dlab-img-overlay6:after,
.dlab-img-overlay6:before,
.dlab-img-overlay7:after,
.dlab-img-overlay7:before,
.dlab-img-overlay8:after,
.dlab-img-overlay8:before,
.dlab-img-overlay9:after,
.dlab-img-overlay9:before {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 1%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 1%);
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 1%);
}
/*  */
.content-header .title i,
.content-header:after,
.review-comments-form,
.travel-time .btn-link span,
.event-listing.listing-bx .listing-info .event-meta .event-date,
.event-listing.listing-bx .event-bottom,
.review-header .dropdown-btn .site-button-link,
.review-btn > ul > li > a,
.review-btn > ul > li > .dropdown > .site-button-link,
.tr-btn-info a.bg-primary-dark:hover,
.tour-booking-form .tour-booking-head,
.tr-price span,
.search-filter .nav-pills .nav-link {
    background: rgba(255, 133, 33, 1);
}
.listing-nav li a.active,
.review-comments-list .review-comment-author .review-avatar,
.review-header .review-comment-author .review-avatar,
.search-form-bx .bootstrap-select .dropdown-toggle,
.search-form-bx .form-control {
    border-color: rgba(255, 133, 33, 1) !important;
}
.featured-bx:before {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 1%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 1%);
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 1%);
}
.listing-nav li a i,
.login-form .info-bottom a,
.login-form-box .btn-link,
.modal-bx-info .btn-link,
.review-comment-author .comment-info .comment-date,
.error-page h4,
.day-details:after,
.testimonial-1.style-2 .testimonial-position,
.packages-bx .head-title .time {
    color: rgba(255, 133, 33, 1) !important;
}
.tp-bg-primary-bx {
    background: rgba(255, 133, 33, 1) !important;
}
.login-form .form-group.input-form {
    border-left: 3px solid rgba(255, 133, 33, 1);
}
